import React, { useState } from "react";
import FaqIcon from "../../assets/images/FAQ/drop.png";
import "./style.scss";

function Accordion(props) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  };
  return (
    <section className="accordion">
      <div className="accordion-header" onClick={onClick}>
        <h4 className="accordion-title">{props.title}</h4>
        <button className="accordion-btn">
          <img
            className={`accordion-icon ${open ? "accordion-icon-active" : ""}`}
            src={FaqIcon}
            alt="accordion-icon"
          ></img>
        </button>
      </div>
      <div className={`accordion-body ${open ? "accordion-body-active" : ""}`}>
        <div className="accordion-body-content">
          {props.custom ? (
            <ul className="accordion-custom">
              <li>
                <i className="fa-solid fa-circle"></i>Fast - Orders are executed
                and settled within a few seconds
              </li>
              <li>
                <i className="fa-solid fa-circle"></i>Cheap - Contracts are
                ultra-gas optimized and fees are minimal
              </li>
              <li>
                <i className="fa-solid fa-circle"></i>Easy - Start trading in
                seconds directly from your wallet
              </li>
              <li>
                <i className="fa-solid fa-circle"></i>Safety - Protect traders
                against liquidation events
              </li>
            </ul>
          ) : (
            props.content
          )}
        </div>
      </div>
    </section>
  );
}

export default Accordion;
