import React from "react";
import WhyCardImg1 from "../../assets/images/Why/1.png";
import WhyCardImg2 from "../../assets/images/Why/2.png";
import WhyCardImg3 from "../../assets/images/Why/3.png";
import WhyCardImg4 from "../../assets/images/Why/4.png";
import WhyCardImg5 from "../../assets/images/Why/5.png";
import WhyCardImg6 from "../../assets/images/Why/6.png";
import BG from "../../assets/images/BG/bg-3.png";
import "./style.scss";

export default function Why() {
  return (
    <section className="why reveal reveal-top">
      <div className="container">
        <h5 className="why-title">WHY CHOOSE THE FTDEX EXCHANGE</h5>
        <ul className="why-card-list">
          <div className="row">
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg1} alt="why-card-item-img" />
              </div>
              <p>Completed Ecosystem with smart utilities</p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg2} alt="why-card-item-img" />
              </div>
              <p>
                Instantly swap any cryptocurrencies assets without registration
              </p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg3} alt="why-card-item-img" />
              </div>
              <p>
                Perpetual Futures trading by 30x leverage through Web3 wallet
              </p>
            </li>
          </div>
          <div className="row">
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg4} alt="why-card-item-img" />
              </div>
              <p>Protect Traders against liquidation events</p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg5} alt="why-card-item-img" />
              </div>
              <p>Trading with very low transaction fees</p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg6} alt="why-card-item-img" />
              </div>
              <p>New ways to earn by Lottery and Prediction</p>
            </li>
          </div>
        </ul>
        <ul className="why-card-list-mb">
          <div className="row">
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg1} alt="why-card-item-img" />
              </div>
              <p>Completed Ecosystem with smart utilities</p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg2} alt="why-card-item-img" />
              </div>
              <p>
                Instantly swap any cryptocurrencies assets without registration
              </p>
            </li>
          </div>
          <div className="row">
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg3} alt="why-card-item-img" />
              </div>
              <p>
                Perpetual Futures trading by 30x leverage through Web3 wallet
              </p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg4} alt="why-card-item-img" />
              </div>
              <p>Protect Traders against liquidation events</p>
            </li>
          </div>
          <div className="row">
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg5} alt="why-card-item-img" />
              </div>
              <p>Trading with very low transaction fees</p>
            </li>
            <li className="why-card-item">
              <div className="why-card-img">
                <img src={WhyCardImg6} alt="why-card-item-img" />
              </div>
              <p>New ways to earn by Lottery and Prediction</p>
            </li>
          </div>
        </ul>
      </div>
      <img className="why-bg" src={BG} alt="why-bg"></img>
    </section>
  );
}
