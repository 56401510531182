import { useEffect, useRef, useState } from "react";
import About from "./components/About/About";
import Community from "./components/Community/Community";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Popup from "./components/Popup/Popup";
import Switch from "./components/Switch/Switch";
import Why from "./components/Why/Why";
import "./style.scss";

function App() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.pageYOffset);
      const reveals = document.querySelectorAll(".reveal");
      const leftReveals = document.querySelectorAll(".reveal-left");
      const rightReveals = document.querySelectorAll(".reveal-right");
      const rightReveal1 = document.querySelector(".reveal-right-1");
      const rightReveal2 = document.querySelector(".reveal-right-2");
      const rightReveal3 = document.querySelector(".reveal-right-3");
      const rightReveal4 = document.querySelector(".reveal-right-4");
      const topReveals = document.querySelectorAll(".reveal-top");
      for (let i = 0; i < leftReveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = leftReveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          leftReveals[i].classList.add("active");
          rightReveals[i].classList.add("active");
          rightReveal1.classList.add("active");
          rightReveal2.classList.add("active");
          rightReveal3.classList.add("active");
          rightReveal4.classList.add("active");
        } else {
          leftReveals[i].classList.remove("active");
          rightReveals[i].classList.remove("active");
          rightReveal1.classList.remove("active");
          rightReveal2.classList.remove("active");
          rightReveal3.classList.remove("active");
          rightReveal4.classList.remove("active");
        }
      }
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
      for (let i = 0; i < topReveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = topReveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          topReveals[i].classList.add("active");
        } else {
          topReveals[i].classList.remove("active");
        }
      }
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);

  return (
    <div className="app">
      <Popup />
      <Header />
      <About />
      <Switch />
      <Why />
      <FAQ />
      <Community />
      <Footer />
    </div>
  );
}

export default App;
