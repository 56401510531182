import React, { useEffect, useRef, useState } from "react";
import PopupClose from "../../assets/images/popup/close.png";
import PopupMainImg from "../../assets/images/popup/section.png";
import PopupEmail from "../../assets/images/popup/email.png";
import Copy from "../../assets/images/popup/copy.png";
import Tick from "../../assets/images/popup/tick.png";
import "./style.scss";

// function useOutsideAlerter(ref) {
//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         alert("You clicked outside of me!");
//       }
//     }
//     // Bind the event listener
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [ref]);
// }

export default function Popup() {
  const [isHidden, setIsHidden] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      console.log(event);
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.which === 1) {
          setIsHidden(true);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={`popup-wrapper ${isHidden ? "hidden" : ""}`}>
      <div className="popup">
        <div className="popup-header">
          <img
            className="popup-header-close"
            src={PopupClose}
            alt="popup-header-close"
            onClick={() => {
              setIsHidden(true);
            }}
          />
        </div>
        <div ref={wrapperRef} className="popup-main">
          <div className="popup-main-left">
            <img src={PopupMainImg} alt="popup-main-img" />
            <div className="popup-main-left-email">
              <span>hello@ftdex.finance</span>
              <img src={isCopy ? Tick : Copy} alt="popup-main-email-img" onClick={() => {
                setIsCopy(true);
                navigator.clipboard.writeText("hello@ftdex.finance");
              }}/>
            </div>
          </div>
          <div className="popup-main-right">
            <h5>
              Join us to be a part of <br /> FTDEX Finance
            </h5>
            <p>
              Want to become an Angel Investor? <br /> Get in touch with us to
              join the DAO team
            </p>
            <a href="mailto:hello@ftdex.finance" className="btn btn-primary">
              <img src={PopupEmail} alt="popup-email-img" />
              <span>Send Email</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
