import React, { useState } from "react";
import Switch1 from "../../assets/images/Ultility/ultility-1.png";
import Switch2 from "../../assets/images/Ultility/ultility-2.png";
import shortid from "shortid";
import "./style.scss";

export default function Switch() {
  const [currentTab, setCurrentTab] = useState("1");

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };
  return (
    <div id="switch" className="switch reveal reveal-top">
      <div className="container">
        <div className="switch-title">
          <button
            id="1"
            className={`${currentTab === "1" ? "active" : ""}`}
            onClick={handleTabClick}
          >
            UTILITY
          </button>
          <button
            id="2"
            className={`${currentTab === "2" ? "active" : ""}`}
            onClick={handleTabClick}
          >
            LOTTERY
          </button>
        </div>

        {/* <div class="tabs">
          <input type="radio" id="radio-1" name="tabs" checked />
          <label class="tab" for="radio-1">
            Upcoming
          </label>
          <input type="radio" id="radio-2" name="tabs" />
          <label class="tab" for="radio-2">
            Development
          </label>
          <span class="glider"></span>
        </div> */}

        <div className="test">
          <div className="square"></div>
        </div>

        <div className="switch-card-wrapper">
          {currentTab === "1" ? (
            <div
              key={currentTab}
              className={`switch-card ${currentTab === "1" ? "fading" : ""}`}
            >
              <div className={`switch-card-content`}>
                <h5 className="switch-card-title">
                  SUPER UTILITY CONVENIENT FOR TRADERS
                </h5>
                <p className="switch-card-text">
                  Aims to become the fit solution for traders who want to stay
                  in control of their funds at all times without sharing their
                  personal data. The innovative design gives many advantages
                  over other existing DEXes. Dynamic pricing is relying on
                  Chainlink and an aggregate of prices from leading volume
                  exchanges to protect traders against liquidation events.
                  <span>
                    Instantly trade cryptocurrencies, tokens, NFT assets,... No
                    registration or account is required. Manage everything in
                    your private wallet
                  </span>
                </p>
                <a
                  href="https://app.ftdex.finance/#/dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="switch-card-link "
                >{`LAUNCH APP >>`}</a>
              </div>
              <div className={`switch-card-img`}>
                <img src={Switch1} alt="switch-card-img" />
              </div>
            </div>
          ) : (
            <div
              key={currentTab}
              className={`switch-card ${currentTab === "2" ? "fading" : ""} `}
            >
              <div className={`switch-card-content`}>
                <h5 className={`switch-card-title`}>
                  LOOK FOR OPPORTUNITIES TO WIN MONEY
                </h5>
                <p className="switch-card-text">
                  By spending a small number of tokens to buy a ticket and join
                  the lottery system on FTDEX, you have a chance to win huge
                  prizes. You can enter as often as you like as long as you have
                  the FTD to buy a ticket.
                  <span>
                    The prediction feature is a fun and simple decentralized
                    prediction system. Use your market knowledge or gut feeling
                    to predict if the FTD price will go up or down in the near
                    future. Get it right and you’ll collect prizes.
                  </span>
                </p>
                <a href="#" className="switch-card-link ">{`JOIN NOW >>`}</a>
              </div>
              <div className={`switch-card-img`}>
                <img src={Switch2} alt="switch-card-img" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
