import React from "react";
import Accordion from "../Accordion/Accordion";
import BG from "../../assets/images/BG/bg-4.png";

import "./style.scss";

export default function FAQ() {
  return (
    <div className="faq reveal reveal-top">
      <div className="container">
        <div className="faq-title">
          <span className="text-pink">F.A.Q</span>
          <h5>FREQUENTLY ASKED QUESTIONS</h5>
        </div>
        <ul className="faq-list">
          <li className="faq-item">
            <Accordion
              title="Why use FTDEX?"
              // content="This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions"
              custom={true}
            />
          </li>
          <li className="faq-item">
            <Accordion
              title="How does Perpetual Futures trading work?"
              content="Perpetual Futures trading is a derivative trading form of FTDEX exchange. Perpetual Futures trading on FTDEX allows investors to place orders on their speculation about a coin's price movement without owning it. So Perpetual Futures trading on FTDEX facilitates profiting both in bull and bear markets."
            />
          </li>
          <li className="faq-item">
            <Accordion
              title="How are prices determined when trading?"
              content="FTDEX is a decentralized trading exchange. You submit an order and that order is filled using dynamic pricing relying on Chainlink and an aggregate of prices from leading volume exchanges, which takes into account market conditions like volatility, liquidation events,…"
            />
          </li>
          <li className="faq-item">
            <Accordion
              title="What is leverage on Perpetual Futures trading?"
              content="Leverage multiplies your buy power, hence your profits and losses. You can select your leverage on every trade from 1x to 30x, or you may not use leverage by pulling the leverage to zero."
            />
          </li>
          <li className="faq-item">
            <Accordion
              title="How to earn money on FTDEX without trading?"
              content="Join the Lottery and Prediction system, by sharing a small number of tokens into the prize pool through the purchase of lottery tickets and you have a chance to win huge prizes. Or simpler by participating in a cryptocurrency price prediction game to earn daily money."
            />
          </li>
        </ul>
      </div>
      <img className="faq-bg" src={BG} alt="community-bg"></img>
    </div>
  );
}
