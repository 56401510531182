import React from "react";
import Logo from "../../assets/images/logo.png";
import "./style.scss";

export default function Footer() {
  return (
    <div className="footer-full reveal reveal-top">
      <div className="container">
        <footer className="footer">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={Logo} alt="footer-logo" />
            </div>
            <p className="footer-desc">
              The decentralized spot and perpetual exchange that supports
              low-cap crypto assets. FTDEX is a free decentralized trading
              protocol designed to be fast and easy to use by anyone
            </p>
          </div>
          <div className="footer-right">
            <ul className="footer-socials">
              <li>
                <a
                  href="https://twitter.com/FTDEXFinance"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/ftdexfinance"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  <i className="fa-brands fa-discord"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/FTDEX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </li>
              <li>
                <a href="mailto:hello@ftdex.finance" className="social-link">
                  <i className="fa-solid fa-envelope"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/@FTDEXFinance"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  <i className="fa-brands fa-medium"></i>
                </a>
              </li>
            </ul>
            <ul className="footer-links">
              <li>
                <a href="#">Dashboard</a>
              </li>
              <li>
                <a href="#">Earn</a>
              </li>
              <li>
                <a href="#">Referrals</a>
              </li>
              <li>
                <a href="#">Ecosystem</a>
              </li>
              <li>
                <a
                  href="https://ftdex.gitbook.io/docs/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Docs
                </a>
              </li>
              <li>
                <a
                  href="https://app.ftdex.finance/#/dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-custom">
                  Launch APP
                </a>
              </li>
            </ul>
          </div>
        </footer>
        <div className="footer-line"></div>
        <span className="copyright">
          Copyright ©2022 FTDEX Finance. All rights reserved
        </span>
      </div>
    </div>
  );
}
