import React from "react";

import "./style.scss";

export default function Community() {
  return (
    <div className="container-min reveal reveal-top">
      <div className="community">
        <h5>BE A PART OF FTDEX FINANCE</h5>
        <p>
          Join our passionate community of investors, where you can meet traders
          and ask our team any questions!
        </p>
        <div className="community-btn">
          <ul className="community-socials">
            <li>
              <a
                href="https://twitter.com/FTDEXFinance"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/ftdexfinance"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link">
                <i className="fa-brands fa-discord"></i>
              </a>
            </li>
            <li>
              <a
                href="https://t.me/FTDEX"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </li>

            <li>
              <a
                href="https://medium.com/@FTDEXFinance"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link">
                <i className="fa-brands fa-medium"></i>
              </a>
            </li>
          </ul>
          <a href="mailto:hello@ftdex.finance" className="btn btn-primary">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}
