import React from "react";
import AboutMainImg from "../../assets/images/About/section-2.png";
import AboutCardItem1 from "../../assets/images/About/1.png";
import AboutCardItem2 from "../../assets/images/About/2.png";
import AboutCardItem3 from "../../assets/images/About/3.png";
import AboutCardItem4 from "../../assets/images/About/4.png";
import BG from "../../assets/images/BG/bg-2.png";
import "./style.scss";

export default function About() {
  return (
    <section className={`about`}>
      <div className="container">
        <div className="about-title">
          <span className="text-pink">about ftdex protocol</span>
          <h5>
            THE DECENTRALIZED SPOT AND PERPETUAL EXCHANGE THAT SUPPORTS LOW-CAP
            CRYPTO ASSETS
          </h5>
          <p>
            FTDEX is a free decentralized trading protocol designed to be fast
            and easy to use by anyone
          </p>
        </div>
        <div className="about-main">
          <div className={`about-main-left reveal-left `}>
            <img
              className="about-main-img"
              src={AboutMainImg}
              alt="about-main-img"
            />
          </div>
          <div className={`about-main-right`}>
            <ul className={`about-card-list reveal-right`}>
              <div className="about-card-item-wrapper">
                <li className="about-card-item reveal-right-1">
                  <div className="about-card-item-img">
                    <img src={AboutCardItem1} alt="about-card-item" />
                  </div>
                  <p className="about-card-item-content">
                    Perpetual Futures trading with up to 30x leverage directly
                    from your private wallet, no account needed
                  </p>
                </li>
              </div>
              <div className="about-card-item-wrapper">
                <li className="about-card-item reveal-right-2">
                  <div className="about-card-item-img">
                    <img src={AboutCardItem2} alt="about-card-item" />
                  </div>
                  <p className="about-card-item-content">
                    Spot trading top cryptocurrencies with swaps and limit
                    orders. Easily swap from any supported asset to an asset of
                    your choice
                  </p>
                </li>
              </div>
              <div className="about-card-item-wrapper">
                <li className="about-card-item reveal-right-3">
                  <div className="about-card-item-img">
                    <img src={AboutCardItem3} alt="about-card-item" />
                  </div>
                  <p className="about-card-item-content">
                    An aggregate of high-quality price feeds determines when
                    liquidations occur. This reduces the risk of liquidations
                    from temporary wicks
                  </p>
                </li>
              </div>
              <div className="about-card-item-wrapper">
                <li className="about-card-item reveal-right-4">
                  <div className="about-card-item-img">
                    <img src={AboutCardItem4} alt="about-card-item" />
                  </div>
                  <p className="about-card-item-content">
                    Enter and exit positions with minimal spread and zero price
                    impact. Get the optimal price without incurring additional
                    costs
                  </p>
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div className="about-title reveal-top mt-100">
          <span className="text-pink">ABOUT WIN-WIN PARADIGM</span>
          <h5>
            TRADING ON EXCHANGES OR SEEK FOR OPPORTUNITIES IN LOTTERY AND
            PREDICTION SYSTEM
          </h5>
          <p>
            Traders can approach profit-making activities in different ways.
            Spot trading directly from your Web3 wallet with swaps and limit
            orders. Perpetual Futures trading with up to 30x leverage on short
            and long positions. Playing the lottery in FTDEX gives you a chance
            to win huge prizes! Moreover, you can also participate in predicting
            the price of tokens at a time point. Guess correctly to win, it's
            easy and fair
          </p>
        </div>
      </div>
      <img className="about-bg" src={BG} alt="about-bg"></img>
    </section>
  );
}
