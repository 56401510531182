import React, { useRef, useState } from "react";
import BGVideo from "../../assets/images/section-top/section1-bg.mp4";
import MenuLogo from "../../assets/images/logo.png";
import IntroImg from "../../assets/images/section-top/section-1.png";

import "./style.scss";

export default function Header() {
  const [isMenuMbActive, setIsMenuMbActive] = useState(false);
  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.8;
  };
  return (
    <section className="section-top">
      <ul className={`header-menu-mb ${isMenuMbActive ? "active" : ""}`}>
        <li>
          <a href="#" className="menu-link">
            Dashboard
          </a>
        </li>
        <li>
          <a href="#" className="menu-link">
            Earn
          </a>
        </li>
        <li>
          <a href="#" className="menu-link">
            Utility
          </a>
        </li>
        <li>
          <a href="#" className="menu-link">
            Referrals
          </a>
        </li>
        <li>
          <a href="#" className="menu-link">
            Ecosystem
          </a>
        </li>
        <li>
          <a
            href="https://ftdex.gitbook.io/docs/"
            target="_blank"
            rel="noopener noreferrer"
            className="menu-link">
            Docs
          </a>
        </li>
      </ul>
      <video
        ref={videoRef}
        onCanPlay={() => setPlayBack()}
        autoPlay
        muted
        loop
        id="section-top-video">
        <source src={BGVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="section-top-wrapper">
        <div className="container">
          <header className="header">
            <i
              className="header-nav fa-solid fa-bars"
              onClick={() => {
                setIsMenuMbActive(!isMenuMbActive);
              }}></i>
            <ul className="header-menu">
              <li>
                <a href="#" className="menu-link">
                  <img className="menu-logo" src={MenuLogo} alt="header-logo" />
                </a>
              </li>
              <li>
                <a href="#" className="menu-link">
                  Dashboard
                </a>
              </li>
              <li>
                <a href="#" className="menu-link">
                  Earn
                </a>
              </li>
              <li>
                <a href="#switch" className="menu-link">
                  Utility
                </a>
              </li>
              <li>
                <a href="#" className="menu-link">
                  Referrals
                </a>
              </li>
              <li>
                <a href="#" className="menu-link">
                  Ecosystem
                </a>
              </li>
              <li>
                <a
                  href="https://ftdex.gitbook.io/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu-link">
                  Docs
                </a>
              </li>
            </ul>

            <a
              className="btn btn-primary"
              href="https://app.ftdex.finance/#/dashboard"
              target="_blank"
              rel="noopener noreferrer">
              LAUNCH APP
            </a>
          </header>

          <div className="intro">
            <div className="intro-left">
              <span className="text-pink">INTRODUCING FTDEX FINANCE</span>
              <h4 className="intro-title">
                Decentralized and perpetual exchange
              </h4>
              <p>
                Trade the top cryptocurrencies with higher leverage <br />
                directly from your wallet
                <br />
                0x6807D6FD9f29e171Fa7da53F0cf99B52ad08e23C
              </p>
              <a
                href="https://app.ftdex.finance/#/dashboard"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary">
                LAUNCH APP
              </a>
              <a
                className="btn btn-primary btn-bot"
                href="https://t.me/ftdex_bot"
                target="_blank"
                rel="noopener noreferrer">
                TELEGRAM BOT
              </a>
            </div>
            <div className="intro-right">
              <img
                className="intro-img rotating"
                src={IntroImg}
                alt="intro-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
